import React from 'react'
import UserAvatar from '../user-avatar/user-avatar.component'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../../contexts/auth.context';
import Divider from '@mui/material/Divider';
import { IoCheckmarkCircle, IoEllipseOutline } from 'react-icons/io5';
import { getCurrentBusinessProfile, setCurrentBusinessProfile } from '../../utils/storage';
import { useModal } from '../../contexts/modal.context';
import CreateBusinessProfile from '../biz-profile-create/biz-profile-create';

const BizProfileSwitcher = () => {
    const { currentUser } = useAuth();
    const { data: profile } = useQuery({ queryKey: ['profiles', currentUser.uid] });
    const activeProfile = getCurrentBusinessProfile();
    const { setModal } = useModal();

    const changeBusinessProfile = (slug) => {
        setCurrentBusinessProfile(slug);
        window.location = slug === "user" ? "/user" : `/restaurant`;
    };

    const userRow = (
        <div className='navprofile-profile w-100 mb-20 cursor-pointer' onClick={() => changeBusinessProfile("user")}>
            <UserAvatar profile={profile} />
            <div className='flex-column w-80'>
                <div className='navprofile-username'>{profile.name}</div>
                <div className='navprofile-email'>{profile.email}</div>
            </div>
            <div>
                {activeProfile === "user" ? <IoCheckmarkCircle color={"gray"} size={20} /> : <IoEllipseOutline color={"gray"} size={20} />}
            </div>
        </div>
    );

    return (
        <div className='modal-content modal-small p-2'>
            <h3 className='mt-20'>Switch Account</h3>
            <div className='divider' />
            {userRow}
            {profile.businesses.map(biz => (
                <div
                    key={biz.businessSlug}
                    className='navprofile-profile w-100 mb-20 cursor-pointer'
                    onClick={() => changeBusinessProfile(biz.businessSlug)}
                >
                    <UserAvatar profile={biz} />
                    <div className='flex-column w-80'>
                        <div className='navprofile-username'>{biz.name}</div>
                        <div className='navprofile-email'>{biz.location}</div>
                    </div>
                    <div>
                        {activeProfile === biz.businessSlug ? <IoCheckmarkCircle color={"gray"} size={20} /> : <IoEllipseOutline color={"gray"} size={20} />}
                    </div>
                </div>
            ))}
            <div className='w-100'>
                <Divider component="div" role="presentation"><div className='gray'>{"Or"}</div></Divider>
            </div>
            <div className='mt-10 font-size-14 w-70 gray text-align-center'>
                {"Account page doesn't exist yet? "}
                <div
                    className='charcoal cursor-pointer font-size-14'
                    onClick={() => setModal({
                        modal: "create-biz-profile",
                        children: <CreateBusinessProfile setModal={setModal} />,
                        show: true
                    })}>
                    {"Create  Restaurant Account"}</div>
            </div>
        </div>
    )
}

export default BizProfileSwitcher