import React, { useEffect, useState } from 'react';
import { useAuth } from "../contexts/auth.context";
import { getUserJobApplications, getJobPostings } from '../api/jobs';
import ScrollToTop from '../components/scrollToTop';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CircularProgress from '../components/circular-progress/circular-progress';
import { useModal } from '../contexts/modal.context';
import { JOBS_KEY, JOB_APPLICATIONS_KEY, PROFILE_KEY } from '../constants/queryCache';
import JobSearchHeader from '../components/job-search-header/job-search-header.component';
import JobSearchList from '../components/job-search-list/job-search-list';
import JobDetails from '../components/job-details/job-details.component';

import "../styles/job-search.scss";
import { updateUserProfile } from '../api/user';
import { useSearchParams } from 'react-router-dom';

const Jobs = () => {
  const { currentUser } = useAuth();
  const { modal } = useModal();
  const [selectedJob, setSelectedJob] = useState(null);

  const [searchParams] = useSearchParams();

  const [keyword, setKeyword] = useState(searchParams.get('keyword') || '');
  const [location, setLocation] = useState(searchParams.get('location') || '');
  const onSubmit = e => {
    e.preventDefault();
    setFilters(old => ({ ...old, keyword, location }));
  }

  const [filters, setFilters] = useState({ keyword, location });

  const { data: profile } = useQuery({ queryKey: [PROFILE_KEY, currentUser?.uid] });

  const { data, isLoading, isError, refetch, isSuccess } = useQuery({
    queryKey: [JOBS_KEY], queryFn: async () => {
      return await getJobPostings(filters);
    },
  });

  const {
    data: jobApplicationResponse,
    isLoading: jobApplicationsIsLoading,
    isError: jobApplicationsIsError,
    refetch: refreshJobApplications
  } = useQuery({
    queryKey: [JOB_APPLICATIONS_KEY], queryFn: async () => {
      if (currentUser) {
        return await getUserJobApplications();
      }
      return { items: [] };
    },
  });

  const queryClient = useQueryClient();
  const updateProfileMutation = useMutation({
    mutationFn: updateUserProfile,
    onSuccess: (data) => {
      queryClient.setQueryData([PROFILE_KEY, data.userId], (old) => {
        return { ...data, businesses: old.businesses }
      });
    },
  })

  useEffect(() => {
    if ((isSuccess && data.items.length > 0 && !selectedJob)
      || (selectedJob && !data.items.map((job) => job.jobPostingId).includes(selectedJob.jobPostingId))) {
      const sortedPostings = [...data.items].sort((a, b) => sortPostings(a, b, jobApplicationResponse?.items || []));
      setSelectedJob(sortedPostings[0]);
    }
  }, [isSuccess, data, selectedJob, jobApplicationResponse]);

  useEffect(() => {
    refetch();
    refreshJobApplications();
  }, [modal.show, refetch, refreshJobApplications]);

  useEffect(() => {
    refetch()
    refreshJobApplications()
  }, [filters, refetch, refreshJobApplications]);

  if (isLoading) return <div className='loading-container min-height-60'><CircularProgress /></div>;

  if (isError || jobApplicationsIsError) return <div className='ml-10'>Error fetching jobs</div>;

  const isFavorite = (jobPosting) => profile?.favorites?.jobPostings?.includes(jobPosting?.jobPostingId);
  const favoriteJob = (jobPosting, add) => updateProfileMutation.mutate({
    favorites: {
      jobPostings: add ? [
        ...(profile?.favorites?.jobPostings || []),
        jobPosting.jobPostingId
      ] : profile?.favorites?.jobPostings?.filter((id) => id !== jobPosting.jobPostingId) || []
    }
  });

  const sortedPostings = [...data.items].sort((a, b) => sortPostings(a, b, jobApplicationResponse?.items || []));

  return (
    <div
      className="flex wrapper w-100 min-vh-60 background-secondary"
    >
      <ScrollToTop />
      <div className='jobs-container'>
        <JobSearchHeader
          totalItems={data.totalItems}
          filters={filters}
          setFilters={setFilters}
          onSubmit={onSubmit}
          keyword={keyword}
          setKeyword={setKeyword}
          location={location}
          setLocation={setLocation}
        />
        <div className="flex justify-between flex-wrap job-search-results-paper">
          <div className="flex-fill">
            <JobSearchList
              currentUser={currentUser}
              profile={profile}
              jobPostings={sortedPostings}
              jobApplications={jobApplicationResponse?.items || []}
              totalItems={data.totalItems}
              selectedJob={selectedJob}
              setSelectedJob={setSelectedJob}
              isFavorite={isFavorite}
              favoriteJob={favoriteJob}
            />
          </div>
          <div className="flex-2 mr-20">
            <JobDetails
              currentUser={currentUser}
              jobPosting={selectedJob}
              jobApplications={jobApplicationResponse?.items || []}
              jobApplicationsLoading={jobApplicationsIsLoading}
              isFavorite={isFavorite(selectedJob)}
              favoriteJob={favoriteJob}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const sortPostings = (a, b, jobApplications) => {
  const aApp = jobApplications.find(app => app.jobPostingId === a.jobPostingId);
  const bApp = jobApplications.find(app => app.jobPostingId === b.jobPostingId);

  if (aApp && !bApp) return 1;
  if (!aApp && bApp) return -1;
  return 0;
}

export default Jobs;