import { Avatar } from '@mui/material';
import React from 'react'
import { Button } from '../button.styles';
import { IoBookmark, IoBookmarkOutline, IoEllipse } from 'react-icons/io5';
import Chip from '../chip/chip.component';
import { mapSalaryTypeToShorthand } from '../../constants/salaryType';
import { useModal } from '../../contexts/modal.context';
import JobApplicationModal from '../job-application/job-application-modal.component';

const JobDetails = (props) => {
    const { currentUser, jobPosting, jobApplications, jobApplicationsLoading, isFavorite, favoriteJob } = props;
    const { setModal } = useModal();

    if (!jobPosting) return null;

    let salary = jobPosting.minSalary?.amount ? `$${jobPosting.minSalary.amount} ${mapSalaryTypeToShorthand(jobPosting.minSalary.timeUnit)}` : "Salary Not Specified";
    if (jobPosting.maxSalary?.amount) {
        salary += ` - $${jobPosting.maxSalary.amount} ${mapSalaryTypeToShorthand(jobPosting.maxSalary.timeUnit)}`;
    }
    const startDate = jobPosting.startDate ? new Date(jobPosting.startDate).toLocaleDateString() : "Immediate";

    const jobApplication = jobApplications.find(app => app.jobPostingId === jobPosting.jobPostingId);
    const isDisabled = jobApplication != null || !currentUser;

    return (
        <div className='job-details-container'>
            <div className='flex flex-row justify-between w-100 mt-20 mb-10'>
                <Avatar alt={jobPosting.business?.name} src={jobPosting.business?.assets?.profile?.url} variant={"rounded"} />
                <div className='ml-20 mr-20 w-100'>
                    <h1>{jobPosting.subtitle}</h1>
                    <div className='gray mt-10'>{jobPosting.business?.name}</div>
                    <div className='job-details-posting-row'>
                        <div className='job-details-posting-item'>{jobPosting.business.location}</div>
                        <IoEllipse style={{ width: "5%" }} size={8} />
                        <div className='job-details-posting-item'>{salary}</div>
                        <IoEllipse style={{ width: "5%" }} size={8} />
                        <div className='job-details-posting-item'>{`Start Date: ${startDate}`}</div>
                        <IoEllipse style={{ width: "5%" }} size={8} />
                        <div className='job-details-posting-item'><b>{`Applicants: `}</b>{jobPosting.applicationCount || 0}</div>
                    </div>
                </div>
                <Button
                    color={isFavorite ? "charcoal" : "white"}
                    icon="true"
                    onClick={currentUser ? () => favoriteJob(jobPosting, !isFavorite) : null}
                    style={{ height: "32px" }}
                >
                    {isFavorite ? <IoBookmark size={20} /> : <IoBookmarkOutline size={20} className={'gray'} />}
                </Button>
            </div>
            <div className={"divider"} />
            <div className='flex flex-column align-start w-100 mt-20'>
                <div className='job-details-header'>{"Job Overview"}</div>
                <div className='mt-20 mb-20 gray'>
                    {jobPosting.description}
                </div>
                <div className='job-details-header mt-20'>{"Benefits of Working With Us"}</div>
                <div className='mt-10 mb-10 flex flex-row align-center'>
                    {jobPosting.benefits.map((benefit) => (
                        <Chip
                            key={benefit}
                            label={benefit} sx={{
                                "marginRight": "10px"
                            }}
                            variant={"filled"}
                        />
                    ))}
                </div>
                <div className='job-details-header mt-20 mb-20'>{"Restaurant Highlights"}</div>
                <div className='mt-20'>
                    <Button
                        color={isDisabled ? "gray" : "aperol"}
                        disabled={isDisabled || jobApplicationsLoading}
                        onClick={() => {
                            setModal({
                                show: true,
                                modal: "job-appliation",
                                children: <JobApplicationModal jobPosting={jobPosting} />,
                                closeIcon: false
                            })
                        }}>
                        {isDisabled && jobApplication?.applicationDate ? `Applied on ${new Date(jobApplication.applicationDate).toLocaleDateString()}` : "Apply Here"}
                    </Button>
                </div>

            </div>
        </div>
    )
}

export default JobDetails