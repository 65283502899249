import React from 'react';
import UserAvatar from '../user-avatar/user-avatar.component';
import { IoEye, IoMailOutline, IoLocationOutline, IoTimeOutline } from 'react-icons/io5';
import { Button } from '../button.styles';
import { useModal } from '../../contexts/modal.context';
import BusinessProfileHeaderForm from './biz-profile-header-form.component';
import { useNavigate } from 'react-router-dom';
// import QRCodeModal from '../qrcode/qrcodemodal';


export const BusinessProfileHeader = (props) => {
    const { setModal } = useModal();
    const { profile, jobPostings, isPublic } = props;
    const navigate = useNavigate();
    const positionsOpen = jobPostings?.filter(post => post.status === "OPEN").length || 0;

    return (
        <div className="user-profile__header charcoal">
            <div className="user-profile__header__background">
                <img src={profile.assets?.background ? profile.assets?.background.url : "/images/default_profile_background.jpeg"} alt="background" />
            </div>
            <div className='container'>
                {!isPublic &&
                    <div className="user-profile__preview_live flex flex-row align-center justify-center" onClick={() => navigate(`/restaurant/${profile.businessSlug}`)}>
                        <IoEye className='mr-5' />
                        {"Preview Live Profile"}
                    </div>
                }
                <div className="user-profile__header__avatarrow ml-20 mr-10">
                    <div className="user-profile__header__avatar">
                        <UserAvatar profile={profile} onProfile textChars={2} />
                    </div>
                    <div className='user-profile__buttons'>
                        <div className='user-profile__button mr-10'>
                            <Button color="white" onClick={() =>
                                setModal({
                                    modal: "edit-biz-profile",
                                    children: <BusinessProfileHeaderForm setModal={setModal} {...props} />,
                                    show: true
                                })
                            }>
                                {"Edit Header"}
                            </Button>
                        </div>
                        {/* <div className='user-profile__button'>
                            <Button color="aperol" onClick={() => setModal({
                                modal: "qr-code",
                                children: <QRCodeModal
                                    url={new URL(`/restaurant/${profile.businessSlug}`, window.location.origin).toString()}
                                />,
                                show: true,
                                closeIcon: false
                            })}>
                                {"Generate QR Code"}
                            </Button>
                        </div> */}
                    </div>
                </div>
                <div className='user-profile__userinfo ml-20'>
                    <h1 className='user-profile__userinfo__name mb-5'>{profile.name}</h1>
                    <div className='user-profile__userinfo__summary mb-20'>{profile.headline}</div>
                    <div className='flex flex-wrap'>
                        {profile.website &&
                            <UserInfoPair value={profile.website}>
                                <IoMailOutline />
                            </UserInfoPair>
                        }
                        <UserInfoPair value={profile.location}>
                            <IoLocationOutline />
                        </UserInfoPair>
                        <UserInfoPair value={`${positionsOpen} Positions Open`}>
                            <IoTimeOutline />
                        </UserInfoPair>
                        {/* {profile.reviewCount > 0 ?
                            <UserInfoPair value={`${profile.stars} Stars (${profile.reviewCount} Reviews)`}>
                                <IoStar />
                            </UserInfoPair>
                            :
                            <div className='ml-10'>
                                {"No Reviews"}
                            </div>
                        } */}
                    </div>
                </div>
            </div>
        </div >
    )
}

const UserInfoPair = (props) => {
    return (
        <div className='user-profile__userinfo__pair'>
            {props.children}
            <div className='ml-5'>{props.value}</div>
        </div>
    )
}

export default BusinessProfileHeader;
