import React, { useEffect, useState } from 'react';
import { getJobApplications, getJobPostings } from '../api/jobs';
import ScrollToTop from '../components/scrollToTop';
import { useQuery } from '@tanstack/react-query';
import CircularProgress from '../components/circular-progress/circular-progress';
import { useModal } from '../contexts/modal.context';
import { JOBS_KEY, JOB_APPLICATIONS_KEY } from '../constants/queryCache';
import { getCurrentBusinessProfile } from '../utils/storage';
import PositionsApplicationsHeader from '../components/positions-applications-header/positions-applications-header.component';
import PositionsApplicationsTable from '../components/positions-applications-table/positions-applications-table.component';
import { useNavigate, useParams } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';


const PositionApplications = () => {
    const { modal } = useModal();
    const { jobPostingId } = useParams();
    const { businessSlug } = getCurrentBusinessProfile();
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    const { data: jobApplications, isLoading, isError, refetch } = useQuery({
        queryKey: [JOB_APPLICATIONS_KEY], queryFn: async () => {
            return await getJobApplications(jobPostingId);
        }
    });

    const { data: jobPostingPagination, isLoading: jobPostingsLoading, isError: jobPostingsError } = useQuery({
        queryKey: [JOBS_KEY, businessSlug], queryFn: async () => {
            return await getJobPostings({ businessSlug });
        }
    });

    const jobPosting = jobPostingPagination?.items.find(jp => jp.jobPostingId === jobPostingId);

    useEffect(() => { refetch() }, [modal.show, refetch]);

    if (isLoading || jobPostingsLoading) return <div className='loading-container min-height-60'><CircularProgress /></div>;

    if (isError || jobPostingsError || !jobPosting) return <div className='ml-10'>Error fetching applications</div>;

    return (
        <div
            className="flex wrapper w-100 min-vh-60 background-secondary flex-column"
        >
            <ScrollToTop />
            <div className='container positions-container'>
                <div className='flex flex-row align-center aperol cursor-pointer' onClick={() => navigate("/positions")}>
                    <IoArrowBack size={20} />
                    <div className='ml-5'>{"Back to Positions Center"}</div>
                </div>
                <div className='mt-10 positions-box'>
                    <PositionsApplicationsHeader businessSlug={businessSlug} jobPosting={jobPosting} search={search} setSearch={setSearch} />
                    <PositionsApplicationsTable jobPosting={jobPosting} jobApplications={jobApplications.items} filter={search} />
                </div>
            </div>
        </div>
    );
};

export default PositionApplications;