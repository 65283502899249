import React, { useEffect } from 'react';
import ScrollToTop from '../components/scrollToTop';

import { useQuery } from '@tanstack/react-query';
import CircularProgress from '../components/circular-progress/circular-progress';

import { useModal } from '../contexts/modal.context';
import { JOBS_KEY, RESTAURANT_KEY } from '../constants/queryCache';
import { useParams } from 'react-router-dom';
import { getCurrentBusinessProfile } from '../utils/storage';
import { getBusinessProfile } from '../api/business';
import BusinessProfileHeader from '../components/biz-profile-header/biz-profile-header.component';
import BusinessProfileSummary from '../components/biz-profile-summary/biz-profile-summary.component';
import BusinessProfilePositionList from '../components/biz-profile-position-list/biz-profile-position-list.component';
//import BusinessProfileReferences from '../components/biz-profile-references/biz-profile-references.component';
import { getJobPostings } from '../api/jobs';

const RestaurantProfile = () => {
    const { businessSlug } = useParams();
    const { modal } = useModal();
    const slug = businessSlug ? businessSlug : getCurrentBusinessProfile();
    const isPublic = businessSlug ? true : false;

    const { data: profile, isLoading, isError, refetch } = useQuery({
        queryKey: [RESTAURANT_KEY, slug], queryFn: async () => {
            return await getBusinessProfile(slug, true);
        }
    });

    const { data: jobPostingPagination, isLoading: jobPostingsLoading, isError: jobPostingsError } = useQuery({
        queryKey: [JOBS_KEY, slug], queryFn: async () => {
            return await getJobPostings({ businessSlug: slug });
        }
    });


    useEffect(() => { refetch() }, [modal.show, refetch]);

    if (isLoading) return <div className='loading-container min-height-60'><CircularProgress /></div>;

    if (isError) return <div className='ml-10'>Error fetching restaurant</div>;

    return (
        <div
            className="flex wrapper w-100 min-vh-60 background-secondary flex-column"
        >
            <ScrollToTop />
            <BusinessProfileHeader profile={profile} jobPostings={jobPostingPagination?.items} isPublic={isPublic} />
            <div className='divider' />
            <div className="flex justify-between flex-wrap container">
                <div className="flex-2 mr-10 ml-10">
                    <BusinessProfileSummary
                        profile={profile}
                        isPublic={isPublic}
                    />
                    <div className="vertical-spacer" />
                    <BusinessProfilePositionList
                        profile={profile}
                        jobPostings={jobPostingPagination?.items}
                        isPublic={isPublic}
                        isLoading={jobPostingsLoading}
                        isError={jobPostingsError}
                    />
                </div>
                {/* <div className="flex-fill">
                    <BusinessProfileReferences profile={profile} isPublic={isPublic} />
                </div> */}
            </div>
        </div>
    );
};

export default RestaurantProfile;