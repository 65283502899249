import React from 'react'
import { useModal } from '../../contexts/modal.context';
import { IoClose, IoTrash } from 'react-icons/io5';
import { charcoal, goji, secondaryButton } from '../../constants/colors';
import Input from '../input/input.component';
import TextArea from '../textarea/textarea.component';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Select from '../select/select.component';
import MenuItem from '../menuitem/menuitem.component';
import { EmploymentTypes } from '../../constants/employmentType';
import { Button } from '../button.styles';
import Autocomplete from '../autocomplete/autocomplete.component';
import Chip from '../chip/chip.component';
import { Benefits } from '../../constants/benefits';
import Wizard from '../wizard/wizard.component';
import { ScreeningQuestionTypes } from '../../constants/screenQuestionTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { JOB_POSITIONS_KEY, addToPaginatedList, updatePaginatedList } from '../../constants/queryCache';
import { createJobPosting, editJobPosting } from '../../api/jobs';
import { getCurrentBusinessProfile } from '../../utils/storage';
import { SalaryTypes } from '../../constants/salaryType';
import SalaryInput from './salary-input.component';

const CreatePositionFormPageOne = props => {
    const { data: jobPosting } = props;
    const [benefits, setBenefits] = React.useState(jobPosting?.benefits || []);

    const {
        watch,
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            jobPostingId: jobPosting?.jobPostingId,
            title: jobPosting?.title,
            subtitle: jobPosting?.subtitle,
            description: jobPosting?.description || "",
            employmentType: jobPosting?.employmentType || EmploymentTypes[0].value,
            minSalaryAmount: jobPosting?.minSalary?.amount || 0,
            minSalaryTimeUnit: jobPosting?.minSalary?.timeUnit || SalaryTypes[0].value,
            maxSalaryAmount: jobPosting?.maxSalary?.amount || 0,
            maxSalaryTimeUnit: jobPosting?.maxSalary?.timeUnit || SalaryTypes[0].value,
        }
    });

    const descriptionWatch = watch("description");

    const nextPage = values => {
        props.setData({ ...values, benefits });
        props.nextPage();
    }

    return (
        <form className='w-100' onSubmit={handleSubmit(nextPage)}>
            <h1>{"Position Information"}</h1>
            <h2 className='mt-20 mb-10'>{"Title"}</h2>
            <Input fullWidth error={errors.title} inputProps={{
                ...register("title", {
                    required: "Title is required", maxLength: { value: 80, message: "Max length of 80 characters" },
                })
            }} />
            <h2 className='mt-20 mb-10'>{"Subtitle"}</h2>
            <Input fullWidth inputProps={{
                ...register("subtitle", {
                    required: "Title is required", maxLength: { value: 200, message: "Max length of 200 characters" },
                })
            }} />
            <h2 className='mt-20 mb-10'>{"Description"}</h2>
            <TextArea fullWidth minRows={2} inputProps={{
                ...register("description", {
                    required: "Description is required", maxLength: { value: 2000, message: "Max length of 2000 characters" },
                })
            }} />
            <div className='flex justify-end font-size-14 gray mt-5'>{`${descriptionWatch.length}/2000`}</div>
            <h2 className='mt-20 mb-10'>{"Employment Type"}</h2>
            <Controller
                name="employmentType"
                render={({ field: { onChange, value } }) => (
                    <Select fullWidth onChange={onChange} value={value}>
                        {EmploymentTypes.map(type => (
                            <MenuItem value={type.value} key={type.value}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </Select>
                )}
                control={control}
            />
            <h2 className='mt-20 mb-10'>{"Minimum Salary"}</h2>
            <SalaryInput control={control} register={register} amountField="minSalaryAmount" timeUnitField="minSalaryTimeUnit" />
            <div className='mt-10' />
            <h2 className='mt-20 mb-10'>{"Maximum Salary"}</h2>
            <SalaryInput control={control} register={register} amountField="maxSalaryAmount" timeUnitField="maxSalaryTimeUnit" />
            <h2 className='mt-20 mb-10'>{"Benefits of Working With Us"}</h2>
            <div className='flex flex-row mt-20 mb-20'>
                {benefits.map((benefit) => (
                    <Chip
                        button
                        key={benefit}
                        label={benefit}
                        sx={{
                            "marginRight": "10px"
                        }}
                        onDelete={() => setBenefits(benefits.filter(bene => bene !== benefit))}
                    />
                ))}
            </div>
            <Autocomplete
                options={Benefits}
                fullWidth
                onChange={(e, value) => {
                    if (value) {
                        setBenefits(prev => [...new Set([...prev, value])])
                    }
                }}
                placeholder="Add Benefit"
            />
            <div className='flex flex-row align-center justify-end mt-20'>
                <Button color="aperol" type="submit">{"Next Section"}</Button>
            </div>
        </form >
    );
}

const CreatePositionFormPageTwo = props => {
    const { data: jobPosting } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            screeningQuestions: jobPosting?.screeningQuestions || [{
                questionType: "PROMPT",
                questionText: "",
            }],
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "screeningQuestions",
    });

    const queryClient = useQueryClient();
    const createJobPostingMutation = useMutation({
        mutationFn: createJobPosting,
        onSuccess: (data) => {
            queryClient.setQueryData([JOB_POSITIONS_KEY], old => addToPaginatedList(old, data));
            props.nextPage();
        },
    });

    const editJobPostingMutation = useMutation({
        mutationFn: editJobPosting,
        onSuccess: (data) => {
            queryClient.setQueryData([JOB_POSITIONS_KEY], old => updatePaginatedList(old, data, d => d.jobPostingId === data.jobPostingId));
            props.nextPage();
        },
    })

    const nextPage = values => {
        const request = {
            ...jobPosting,
            minSalary: {
                amount: jobPosting.minSalaryAmount,
                timeUnit: jobPosting.minSalaryTimeUnit,
            },
            maxSalary: {
                amount: jobPosting.maxSalaryAmount,
                timeUnit: jobPosting.maxSalaryTimeUnit,
            },
            screeningQuestions: values.screeningQuestions,
            businessSlug: getCurrentBusinessProfile()
        }

        if (request.jobPostingId) {
            editJobPostingMutation.mutate(request);
        } else {
            createJobPostingMutation.mutate(request);
        }
    }

    return (
        <form className='w-100' onSubmit={handleSubmit(nextPage)}>
            <h1>{"Screening Questions"}</h1>
            {fields.map((field, index) => (
                <div key={field.id}>
                    <div className='flex flex-row align-center mt-20 mb-10'>
                        <h2 className='mr-10'>{`Question ${index + 1}`}</h2>
                        <IoTrash className="cursor-pointer" color={goji} size={20} onClick={() => remove(index)} />
                    </div>
                    <Controller
                        name={`screeningQuestions.${index}.questionType`}
                        render={({ field: { onChange, value } }) => (
                            <Select fullWidth onChange={onChange} value={value}>
                                {ScreeningQuestionTypes.map(type => (
                                    <MenuItem value={type.value} key={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        control={control}
                    />
                    <div className='mt-10' />
                    <Input fullWidth error={errors.title} placeholder={"Ex. What is your interest in this position?"} inputProps={{
                        ...register(`screeningQuestions.${index}.questionText`, {
                            required: "Question Text is required", maxLength: { value: 200, message: "Max length of 200 characters" },
                        })
                    }} />
                </div>
            ))}

            <div className='mt-20'>
                <Chip label="+ Add Question" button onClick={() => append({
                    questionType: ScreeningQuestionTypes[0].value,
                    questionText: "",
                })} />
            </div>

            <div className='flex flex-row align-center justify-end mt-20'>
                <div className='gray mr-20 cursor-pointer' onClick={() => props.prevPage()}>{"Previous Section"}</div>
                <Button color="aperol" type="submit">{"Post New Position"}</Button>
            </div>
        </form >
    )
}
const CreatePositionFormConfirmation = props => {
    return (
        <div className='flex flex-column align-center justify-center'>
            <h3 className='mt-10 mb-10'>{"New position uploaded successfully!"}</h3>
            <div className='gray mt-10 mb-10'>{"We wish you the best of luck in your search."}</div>
            <div className='mt-10 mb-20'>
                <Button color={"aperol"} onClick={() => props.setModal({ show: false })}>{"Done"}</Button>
            </div>
        </div>
    )
}

const CreatePositionForm = (props) => {
    const { setModal } = useModal();

    const header = (
        <div className='flex flex-row align-center justify-between w-100 pt-20 pb-20 background-secondary'
            style={{ borderBottom: `1px solid ${secondaryButton}` }}>
            <div style={{ marginLeft: "40px", fontWeight: "500" }}>{"Create a New Position"}</div>
            <div className='flex flex-row align-center mr-20 cursor-pointer' onClick={() => setModal({ show: false })}>
                <div className='font-size-14 mr-5'>{"Cancel"}</div>
                <IoClose color={charcoal} size={20} />
            </div>
        </div>
    );

    return (
        <div className='modal-content modal-medium'>
            {header}
            <Wizard
                initialData={props.jobPosting}
                steps={[
                    (wizardProps) => <CreatePositionFormPageOne {...wizardProps} />,
                    (wizardProps) => <CreatePositionFormPageTwo {...wizardProps} />,
                    (wizardProps) => <CreatePositionFormConfirmation {...wizardProps} setModal={setModal} />,
                ]} />
        </div >

    )
}

export default CreatePositionForm