import React from 'react'
import { Button } from '../button.styles';
import Input from '../input/input.component';
import Select from '../select/select.component';
import MenuItem from '../menuitem/menuitem.component';
import Checkbox from '../checkbox/checkbox.component';
import { Months, getYears } from '../../constants/date';
import { DegreeTypes } from '../../constants/degree';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUserProfile } from '../../api/user';
import { PROFILE_KEY } from '../../constants/queryCache';
import { Controller, useForm } from 'react-hook-form';

export const UserProfileDegreeForm = (props) => {
    const { profile, degree, degrees, setDegrees } = props;
    const startDate = new Date(degree.startDate);
    const endDate = degree.endDate ? new Date(degree.endDate) : null;

    const queryClient = useQueryClient();
    const updateProfileMutation = useMutation({
        mutationFn: updateUserProfile,
        onSuccess: (data) => {
            queryClient.setQueryData([PROFILE_KEY, data.userId], data);
            setDegrees({
                ...data.degrees
            })
        },
    })

    const updateProfile = (values) => {
        const mappedValues = {
            degreeId: degree.degreeId,
            school: values.school,
            degreeType: values.degreeType,
            fieldOfStudy: values.fieldOfStudy,
            location: values.location,
            startDate: new Date(values.startYear, values.startMonth),
            endDate: values.currentSchool ? null : new Date(values.endYear, values.endMonth),
        }

        const profileUpdate = {
            ...profile,
            degrees: {
                ...degrees,
                [degree.degreeId]: {
                    ...mappedValues
                }
            }
        }

        updateProfileMutation.mutate(profileUpdate);
    }

    const deleteDegree = (e) => {
        e.preventDefault();
        const profileUpdate = {
            ...profile,
            degrees: Object.fromEntries(Object.entries(degrees).filter(([key]) => key !== degree.degreeId))
        }

        updateProfileMutation.mutate(profileUpdate);
    }

    const { register, handleSubmit, formState: { errors }, control, watch } = useForm({
        defaultValues: {
            school: degree.school,
            degreeType: degree.degreeType || DegreeTypes[0].value,
            fieldOfStudy: degree.fieldOfStudy,
            location: degree.location,
            startMonth: startDate.getMonth() || 1,
            startYear: startDate.getFullYear() || new Date().getFullYear(),
            currentSchool: !endDate,
            endMonth: endDate?.getMonth() || 1,
            endYear: endDate?.getFullYear() || new Date().getFullYear(),
        }
    });
    const watchStartYear = watch("startYear", startDate.getFullYear() || new Date().getFullYear());
    const watchStartMonth = watch("startMonth", startDate.getMonth() || 1);
    const watchEndYear = watch("endYear", endDate?.getFullYear() || new Date().getFullYear());
    const watchCurrentSchool = watch("currentSchool", !endDate);

    return (
        <form className='w-100 mt-20 mb-20' onSubmit={handleSubmit(updateProfile)}>
            <div className='ml-10'>
                <h2 className='mt-20 mb-10'>{"School Name"}</h2>
                <Input fullWidth error={errors.school} inputProps={{ ...register("school", { required: "School Name is required", maxLength: { value: 200, message: "Max length is 200" } }) }} />
                <h2 className='mt-20 mb-10'>{"Degree Type"}</h2>
                <Controller
                    name="degreeType"
                    render={({ field: { onChange, value } }) => (
                        <Select fullWidth onChange={onChange} value={value}>
                            {DegreeTypes.map(type => (
                                <MenuItem value={type.value} key={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    control={control}
                />
                <h2 className='mt-20 mb-10'>{"Field Of Study"}</h2>
                <Input fullWidth error={errors.fieldOfStudy} inputProps={{ ...register("fieldOfStudy", { required: "Field of Study is required", maxLength: { value: 200, message: "Max length is 200" } }) }} />
                <h2 className='mt-20 mb-10'>{"Location"}</h2>
                <Input fullWidth error={errors.location} inputProps={{ ...register("location", { required: "Location is required", maxLength: { value: 200, message: "Max length is 200" } }) }} />
                <h2 className='mt-20 mb-10'>{"Start Date"}</h2>
                <div className='flex flex-row align-center'>
                    <Controller
                        name="startMonth"
                        render={({ field: { onChange, value } }) => (
                            <Select fullWidth onChange={onChange} value={value}>
                                {Months.map((type, index) => (
                                    <MenuItem value={index} key={type} disabled={new Date(watchStartYear, Months.indexOf(type)) > new Date()}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        control={control}
                    />
                    <Controller
                        name="startYear"
                        render={({ field: { onChange, value } }) => (
                            <Select fullWidth onChange={onChange} value={value}>
                                {getYears(1900).map(type => (
                                    <MenuItem value={type} key={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        control={control}
                    />
                </div>
                <Controller
                    name="currentSchool"
                    render={({ field: { onChange, value } }) => (
                        <Checkbox
                            checked={value}
                            onChange={onChange}
                            label={"I currently attend here"}
                        />
                    )}
                    control={control}
                />
                {!watchCurrentSchool && (
                    <>
                        <h2 className='mt-20 mb-10'>{"End Date"}</h2>
                        <div className='flex flex-row align-center'>
                            <Controller
                                name="endMonth"
                                render={({ field: { onChange, value } }) => (
                                    <Select fullWidth onChange={onChange} value={value}>
                                        {Months.map((type, index) => (
                                            <MenuItem value={index} key={type} disabled={new Date(watchEndYear, Months.indexOf(type)) > new Date()}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                control={control}
                                rules={{
                                    validate: month => {
                                        if (new Date(watchStartYear, watchStartMonth) <= new Date(watchEndYear, month)) {
                                            return true;
                                        }
                                        return "End date must be after start date";
                                    }
                                }}
                            />
                            <Controller
                                name="endYear"
                                render={({ field: { onChange, value } }) => (
                                    <Select fullWidth onChange={onChange} value={value}>
                                        {getYears(1900).map(type => (
                                            <MenuItem value={type} key={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                control={control}
                            />
                        </div>
                    </>
                )}
                {errors.endMonth && <div className='text-error'>{errors.endMonth.message}</div>}
            </div>
            <div className='user-profile__buttons'>
                <Button color="danger" className='ml-10 mt-20' onClick={deleteDegree}>{"Delete Degree"}</Button>
                <Button color="aperol" type={"submit"} className='ml-10 mt-20'>{"Save Degree"}</Button>
            </div>
        </form>
    )
}

export default UserProfileDegreeForm;
