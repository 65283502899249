import React, { useState } from 'react'
import { useSpring } from '@react-spring/web'
import {
  HeroContainer,
  HeroBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
} from './hero.styles'
import { useMediaQuery } from 'react-responsive'

import "../../styles/hero.scss";
import { IoArrowForward } from 'react-icons/io5'
import JobSearchBar from '../search-bar/job-search-bar.component'
import { Button } from '../button.styles'
import { useNavigate } from 'react-router-dom';
import { getCurrentBusinessProfile } from '../../utils/storage';
import { getHeroData } from './hero.data';
import { useAuth } from '../../contexts/auth.context';
import { useModal } from '../../contexts/modal.context';

const HeroSection = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  const styles = useSpring({
    config: { duration: 500 },
    easing: {
      value: 'ease-in-out'
    },
    from: {
      opacity: 0,

      transform: "scale(0.9)"
    },
    to: {
      opacity: 1,
      transform: "scale(1)"
    },
  })

  const AnimatedHero = HeroContent;

  const navigate = useNavigate();
  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('');
  const { currentUser } = useAuth();
  const { setShowModalSignUp } = useModal();

  const currentBizProfile = getCurrentBusinessProfile();
  const heroData = getHeroData(currentBizProfile);

  const onSubmit = e => {
    e.preventDefault();
    navigate(`/jobs?keyword=${keyword}&location=${location}`);
  }


  return (
    <HeroContainer id='home'>
      <HeroBg style={{ backgroundImage: `url(${heroData.image})` }}>
      </HeroBg>
      {!isDesktop && <div className="hero__bg-overlay"></div>}
      <AnimatedHero style={styles}>
        <div data-aos="fade-up" data-aos-duration="1400">
          <div className="hero__content">
            <HeroH1>{heroData.title}</HeroH1>
            <HeroP>
              {heroData.description}
            </HeroP>
            {currentBizProfile === "user" &&
              <>
                <HeroBtnWrapper>
                  <JobSearchBar
                    keyword={keyword}
                    setKeyword={setKeyword}
                    location={location}
                    setLocation={setLocation}
                    onSubmit={onSubmit}
                    className={"w-100"}
                    endAdornment={<div>
                      <div className='flex flex-row align-center justify-end'>
                        <Button fontSize="small" color="aperol" type={"submit"}>{"Search"}</Button>
                      </div>
                    </div>}
                  />
                </HeroBtnWrapper>
                <div className='hero__action'>
                  <div className='mr-5' onClick={() => {
                    if (currentUser && currentBizProfile === "user") {
                      navigate("/jobs")
                    } else if (currentUser && currentBizProfile === "user") {
                      navigate("/positions")
                    } else {
                      setShowModalSignUp(true)
                    }
                  }}>
                    {currentUser && currentBizProfile === "user" ? "Start Applying!" : "Looking to hire? Let's find the right person for you"}
                  </div>
                  <IoArrowForward size={20} />
                </div>
              </>
            }

            {currentBizProfile !== "user" &&
              <div className='flex align-center justify-center mt-20'>
                <Button big color={"aperol"} onClick={() => navigate("/positions")}>
                  <div className='mr-5 font-size-large'>
                    {"Start Hiring!"}
                  </div>
                  <IoArrowForward size={20} />
                </Button>
              </div>
            }
          </div>
        </div>
      </AnimatedHero>
      {/* </div> */}
    </HeroContainer>
  )
}

export default HeroSection
