import React, { useEffect } from 'react';
import UserAvatar from '../user-avatar/user-avatar.component';
import { IoEye, IoMailOutline, IoLocationOutline, IoTimeOutline } from 'react-icons/io5';
import { Button } from '../button.styles';
import { mapEmploymentTypeToString } from '../../constants/employmentType';
import { useModal } from '../../contexts/modal.context';
import UserProfileHeaderForm from './user-profile-header-form.component';
import "../../styles/user-profile.scss";
import UploadFile from '../upload-file/upload-file.component';
import CircularProgress from '../circular-progress/circular-progress';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { PROFILE_KEY } from '../../constants/queryCache';
import { getUserProfile } from '../../api/user';


export const UserProfileHeader = (props) => {
    const { setModal } = useModal();
    const { isPublic } = props;
    const navigate = useNavigate();
    const [refetchInterval, setRefetchInterval] = React.useState(0);
    const [resumeUrl, setResumeUrl] = React.useState(null);

    const { data: fetchedProfile } = useQuery({
        queryKey: [PROFILE_KEY, props.profile.userId], queryFn: async () => {
            return await getUserProfile(props.userSlug);
        },
        refetchInterval
    });

    const onResumeUploaded = async (url) => {
        setResumeUrl(url);
        setRefetchInterval(1000 * 3);
    }

    const profile = fetchedProfile || props.profile;
    const resumeStatus = getResumeStatus(profile.assets, resumeUrl);

    useEffect(() => {
        if (resumeStatus.status === "UPLOADED" && refetchInterval > 0 && resumeUrl === profile.assets.resume.url) {
            setRefetchInterval(0);
        }
    }, [resumeStatus, refetchInterval, resumeUrl, profile?.assets?.resume?.url])

    return (
        <div className="user-profile__header">
            <div className="user-profile__header__background">
                <img src={profile.assets?.background ? profile.assets?.background.url : "/images/default_profile_background.jpeg"} alt="background" />
            </div>
            <div className='container'>
                {!isPublic &&
                    <div className="user-profile__preview_live flex flex-row align-center justify-center" onClick={() => navigate(`/user/${profile.userSlug}`)}>
                        <IoEye className='mr-5' />
                        {"Preview Live Profile"}
                    </div>
                }
                <div className="user-profile__header__avatarrow ml-20 mr-10">
                    <div className="user-profile__header__avatar">
                        <UserAvatar profile={profile} onProfile textChars={2} />
                    </div>
                    <div className='user-profile__buttons'>
                        {isPublic ?
                            profile.assets?.resume &&
                            <Button color="aperol">
                                <a href={profile.assets?.resume?.url} download>
                                    {"Download Resume"}
                                </a>
                            </Button>
                            : (
                                <>
                                    <div className='user-profile__button mr-10'>
                                        <Button color="white" onClick={() =>
                                            setModal({
                                                modal: "edit-profile",
                                                children: <UserProfileHeaderForm setModal={setModal} {...props} />,
                                                show: true
                                            })
                                        }>
                                            {"Edit Header"}
                                        </Button>
                                    </div>
                                    <div className='user-profile__button'>
                                        <UploadFile
                                            assetKey="resume"
                                            onFileUploaded={onResumeUploaded}
                                            accept=".pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain"
                                            renderOverlay={({ uploading }) => (
                                                uploading ?
                                                    <Button color="aperol" style={{ minWidth: "150px" }}>
                                                        <CircularProgress color="white" size={20} />
                                                    </Button> :
                                                    <Button color="aperol">
                                                        <label className='cursor-pointer' htmlFor='resume'>
                                                            {resumeStatus.text}
                                                        </label>
                                                    </Button>
                                            )}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className='user-profile__userinfo ml-20'>
                    <h1 className='user-profile__userinfo__name mb-5'>{profile.name}</h1>
                    <div className='user-profile__userinfo__summary mb-20'>{profile.headline}</div>
                    <div className='flex flex-wrap'>
                        {profile.email &&
                            <UserInfoPair value={profile.email}>
                                <IoMailOutline />
                            </UserInfoPair>
                        }
                        <UserInfoPair value={profile.location}>
                            <IoLocationOutline />
                        </UserInfoPair>
                        <UserInfoPair value={mapEmploymentTypeToString(profile.employmentType)}>
                            <IoTimeOutline />
                        </UserInfoPair>
                        {/* {profile.reviewCount > 0 ?
                            <UserInfoPair value={`${profile.stars} Stars (${profile.reviewCount} Reviews)`}>
                                <IoStar />
                            </UserInfoPair>
                            :
                            <div className='ml-10'>
                                {"No Reviews"}
                            </div>
                        } */}
                    </div>
                </div>
            </div>
        </div >
    )
}

const getResumeStatus = (assets, resumeUrl) => {
    if (assets && resumeUrl && assets?.resume?.url !== resumeUrl) {
        return { status: "PENDING", text: "Uploading..." }
    }

    if (assets?.resume) return { status: "UPLOADED", text: `Resume: ${assets.resume?.originalFileName || "Uploaded"}` }


    return { status: "NONE", text: "Upload Resume" };
}


const UserInfoPair = (props) => {
    return (
        <div className='user-profile__userinfo__pair'>
            {props.children}
            <div className='ml-5'>{props.value}</div>
        </div>
    )
}
