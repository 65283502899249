import * as React from 'react';
import List from '@mui/material/List';
import JobSearchTile from './job-search-tile';
import { Pagination, PaginationItem } from '@mui/material';

const JobSearchList = props => {
    const { currentUser, jobPostings, isFavorite, favoriteJob, pages, selectedJob, setSelectedJob } = props;

    return (
        <List className='job-search-results-sidebar'>
            {jobPostings.map((jobPosting, index) => (
                <JobSearchTile
                    key={jobPosting.jobPostingId}
                    currentUser={currentUser}
                    jobPosting={jobPosting}
                    divider={index < jobPostings.length - 1}
                    highlighted={selectedJob && selectedJob.jobPostingId === jobPosting.jobPostingId}
                    onClick={() => setSelectedJob(jobPosting)}
                    isFavorite={isFavorite(jobPosting)}
                    favoriteJob={(add) => favoriteJob(jobPosting, add)}
                />
            ))}
            <div className='flex align-center justify-center mt-10 mb-10'>
                <Pagination
                    count={pages || 1}
                    shape="rounded"
                    renderItem={(item) => (
                        <PaginationItem
                            slots={{ previous: PrevButton, next: NextButton }}
                            {...item}
                        />
                    )}
                />
            </div>
        </List>
    );
}

const PrevButton = () => {
    return (
        <div>{"Previous"}</div>
    )
}

const NextButton = () => {
    return (
        <div>{"Next"}</div>
    )
}

export default JobSearchList;