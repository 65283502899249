import React from 'react'
import { Button } from '../button.styles'
import Table from '../table/table.component'
import { matchSearch } from '../../utils/strings'
import { useModal } from '../../contexts/modal.context'
import { useNavigate } from 'react-router-dom'
import ViewApplicantModal from '../view-applicant-modal/view-applicant-modal.component'

const PositionButtons = props => {
    const { row } = props;
    const { jobPosting, ...rest } = row;
    const { setModal } = useModal();
    return (
        <div className='flex flex-row align-center justify-end'>
            <Button
                color="grayInverted"
                className='mr-20'
                fontSize={"small"}
                onClick={() => setModal({
                    show: true,
                    modal: "view-applicant",
                    children: <ViewApplicantModal jobPosting={jobPosting} jobApplication={rest} />,
                    closeIcon: false
                })}
            >
                {"Review Applicant"}
            </Button>
        </div>
    )
}

const ApplicantName = ({ row }) => {
    const navigate = useNavigate();

    return (
        <div
            className='aperol cursor-pointer'
            onClick={() => navigate(`/user/${row.user.userSlug}`)}
        >
            {row.user.name}
        </div>
    )
}


const columns = [
    { field: 'name', headerName: 'Name', variant: 'head', render: (row) => <ApplicantName row={row} /> },
    {
        field: 'applicationDate',
        headerName: 'Date Recieved',
        render: (row) => <div className='gray'>{new Date(row.applicationDate).toLocaleDateString()}</div>
    },
    {
        field: 'buttons',
        headerName: '',
        render: (row) => <PositionButtons row={row} />
    },
];

const PositionsApplicationsTable = (props) => {
    const { jobApplications, jobPosting, filter } = props;
    const mappedApplicants = jobApplications
        .filter(application => filter && application.user.name ? matchSearch(application.user.name, filter) : true)
        .map(application => {
            return {
                ...application,
                id: application.user.userId,
                jobPosting: jobPosting
            }
        });

    return (
        <div className='p-2'>
            <Table
                rows={mappedApplicants || []}
                columns={columns}
                renderEmpty={() =>
                    <tr>
                        <td>
                            <div className='flex align-center p-2'>
                                {"No applicants yet!"}
                            </div>
                        </td>
                    </tr>
                } />

        </div>
    )
}

export default PositionsApplicationsTable

