import React, { useEffect } from 'react';
import { getJobPostings } from '../api/jobs';
import ScrollToTop from '../components/scrollToTop';
import { useQuery } from '@tanstack/react-query';
import CircularProgress from '../components/circular-progress/circular-progress';
import { useModal } from '../contexts/modal.context';
import { JOB_POSITIONS_KEY } from '../constants/queryCache';
import { getCurrentBusinessProfile } from '../utils/storage';
import PositionsHeader from '../components/positions-header/positions-header.component';
import PositionsTable from '../components/positions-table/positions-table.component';

import "../styles/positions.scss";

const Positions = () => {
  const { modal } = useModal();
  const businessSlug = getCurrentBusinessProfile();
  const [search, setSearch] = React.useState('');

  const { data: jobPostings, isLoading, isError, refetch } = useQuery({
    queryKey: [JOB_POSITIONS_KEY], queryFn: async () => {
      return await getJobPostings({ businessSlug });
    }
  });

  useEffect(() => { refetch() }, [modal.show, refetch]);

  if (isLoading) return <div className='loading-container min-height-60'><CircularProgress /></div>;

  if (isError) return <div className='ml-10'>Error fetching positions</div>;

  return (
    <div
      className="flex wrapper w-100 min-vh-60 background-secondary"
    >
      <ScrollToTop />
      <div className='container positions-container positions-box'>
        <PositionsHeader businessSlug={businessSlug} jobPostings={jobPostings.items} search={search} setSearch={setSearch} />
        <PositionsTable jobPostings={jobPostings.items} filter={search} />
      </div>
    </div>
  );
};

export default Positions;