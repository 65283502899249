import React from 'react'
import { useModal } from '../../contexts/modal.context';
import { IoClose, IoWarning } from 'react-icons/io5';
import { IoMdCloudUpload, IoMdDownload } from "react-icons/io";
import { charcoal, secondaryButton } from '../../constants/colors';
import Input from '../input/input.component';
import TextArea from '../textarea/textarea.component';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../button.styles';
import Chip from '../chip/chip.component';
import Wizard from '../wizard/wizard.component';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PROFILE_KEY } from '../../constants/queryCache';
import { useAuth } from '../../contexts/auth.context';
import "../../styles/job-application.scss";
import { applyToJob } from '../../api/jobs';
import { useNavigate } from 'react-router-dom';

const JobApplicationPageOne = props => {
    const { data: { jobPosting, jobApplication }, profile } = props;

    const navigate = useNavigate();
    const { setModal } = useModal();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            jobPostingId: jobPosting?.jobPostingId,
            name: profile.name,
            email: profile.email,
            city: jobApplication.city || "",
            daysAvailable: jobApplication.daysAvailable || new Set()
        }
    });

    const nextPage = values => {
        props.setData({ jobPosting, jobApplication: { ...jobApplication, ...values } });
        props.nextPage();
    }

    const resumeLastUsed = profile?.assets?.resume?.lastUsed ? new Date(profile.assets.resume.lastUsed).toLocaleDateString() : "Never";

    return (
        <form className='w-100' onSubmit={handleSubmit(nextPage)}>
            <h1>{`Contact Information`}</h1>
            <h2 className='mt-20 mb-10'>{"Name"}</h2>
            <Input fullWidth error={errors.name} disabled inputProps={{
                ...register("name", {
                    required: "Name is required", maxLength: { value: 80, message: "Max length of 80 characters" },
                })
            }} />
            <h2 className='mt-20 mb-10'>{"Email"}</h2>
            <Input fullWidth disabled inputProps={{
                ...register("email", {
                    required: "Email is required", maxLength: { value: 200, message: "Max length of 200 characters" },
                })
            }} />
            <h2 className='mt-20 mb-10'>{"City of Residence"}</h2>
            <Input fullWidth inputProps={{
                ...register("city", {
                    required: "City is required", maxLength: { value: 200, message: "Max length of 200 characters" },
                })
            }} />
            <h2 className='mt-20 mb-10'>{"Resume"}</h2>
            <div className='resume-container'>
                <div className='flex-column'>
                    <div className='font-size-14'>
                        {profile?.assets?.resume ?
                            <b className='aperol'>{"Resume"}</b> :
                            <div className='danger flex flex-row align-center'>
                                <IoWarning color={"rgb(181, 20, 20, 0.6)"} size={20} />
                                <b className='ml-10'>{"No Resume Uploaded!"}</b>
                            </div>
                        }
                    </div>
                    {profile?.assets?.resume &&
                        <div className='font-size-12 gray'>{`Last Used: ${resumeLastUsed}`}</div>
                    }
                </div>
                <div className='download-button cursor-pointer'>
                    {profile?.assets?.resume ?
                        <a href={profile.assets.resume.url} download>
                            <IoMdDownload color={"black"} size={20} />
                        </a> :
                        <div onClick={() => {
                            navigate("/user");
                            setModal({ show: false, modal: "edit-profile" });
                        }}>
                            <IoMdCloudUpload color={"black"} size={20} />
                        </div>
                    }
                </div>
            </div>
            <h2 className='mt-20 mb-10'>{"Days Available to Work"}</h2>
            <Controller
                name="daysAvailable"
                render={({ field: { onChange, value } }) => (
                    <div className='flex flex-row'>
                        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => (
                            <div className='mr-10' key={day}>
                                <Chip label={day} button={value.has(day)} onClick={() => {
                                    if (value.has(day)) value.delete(day)
                                    else {
                                        value.add(day)
                                    }
                                    onChange(value)
                                }} />
                            </div>
                        ))}
                    </div>
                )}
                control={control}
            />

            <div className='flex flex-row align-center justify-end mt-20'>
                <Button color="aperol" type="submit" disabled={!profile?.assets?.resume}>{"Next Section"}</Button>
            </div>
        </form >
    );
}

const JobApplicationPageTwo = props => {
    const { data: { jobPosting, jobApplication } } = props;

    const {
        register,
        handleSubmit,
    } = useForm({
        defaultValues: {
            screeningAnswers: jobApplication.screeningAnswers || [],
        }
    });

    const applyToJobMutation = useMutation({
        mutationFn: applyToJob,
        onSuccess: (data) => {
            props.nextPage();
        },
    });

    const nextPage = values => {
        const request = {
            ...jobApplication,
            daysAvailable: Array.from(jobApplication.daysAvailable),
            screeningAnswers: values.screeningAnswers,
        }

        applyToJobMutation.mutate(request);
    }

    return (
        <form className='w-100' onSubmit={handleSubmit(nextPage)}>
            <h1>{"Screening Questions"}</h1>
            {jobPosting.screeningQuestions.map((question, index) => (
                <div key={index}>
                    <div className='flex flex-row align-center mt-20 mb-10'>
                        <h2 className='mr-10'>{`Question ${index + 1}`}</h2>
                    </div>
                    <h2>{question.questionText}</h2>
                    <div className='mt-10' />
                    <TextArea fullWidth minRows={2} inputProps={{
                        ...register(`screeningAnswers.${index}`, {
                            required: "Question is required", maxLength: { value: 1000, message: "Max length of 1000 characters" },
                        })
                    }} />
                </div>
            ))}

            <div className='flex flex-row align-center justify-end mt-20'>
                <div className='gray mr-20 cursor-pointer' onClick={() => props.prevPage()}>{"Previous Section"}</div>
                <Button color="aperol" type="submit">{"Submit Application"}</Button>
            </div>
        </form >
    )
}
const JobApplicationPageThree = props => {
    return (
        <div className='flex flex-column align-center justify-center'>
            <h3 className='mt-10 mb-10'>{"Thank you for your application!"}</h3>
            <div className='gray mt-10 mb-10'>{`${props.data.jobPosting?.business.name || "We"} will get back to you as soon as possible about your status`}</div>
            <div className='mt-10 mb-20'>
                <Button color={"aperol"} onClick={() => props.setModal({ show: false })}>{"Done"}</Button>
            </div>
        </div>
    )
}

const JobApplicationModal = (props) => {
    const { setModal } = useModal();
    const { currentUser } = useAuth();

    const { data: profile } = useQuery({
        queryKey: [PROFILE_KEY, currentUser.uid]
    });

    const header = (
        <div className='flex flex-row align-center justify-between w-100 pt-20 pb-20 background-secondary'
            style={{ borderBottom: `1px solid ${secondaryButton}` }}>
            <div style={{ marginLeft: "40px", fontWeight: "500" }}>{`Application for ${props.jobPosting?.business.name}`}</div>
            <div className='flex flex-row align-center mr-20 cursor-pointer' onClick={() => setModal({ show: false })}>
                <div className='font-size-14 mr-5'>{"Cancel"}</div>
                <IoClose color={charcoal} size={20} />
            </div>
        </div>
    );

    return (
        <div className='modal-content modal-medium'>
            {header}
            <Wizard
                initialData={{ jobPosting: props.jobPosting, jobApplication: {} }}
                steps={[
                    (wizardProps) => <JobApplicationPageOne {...wizardProps} profile={profile} />,
                    (wizardProps) => <JobApplicationPageTwo {...wizardProps} />,
                    (wizardProps) => <JobApplicationPageThree {...wizardProps} setModal={setModal} profile={profile} />,
                ]} />
        </div >

    )
}

export default JobApplicationModal