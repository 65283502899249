import React from 'react'
import { useModal } from '../../contexts/modal.context';
import { IoClose, IoWarning } from 'react-icons/io5';
import { charcoal, secondaryButton } from '../../constants/colors';
import { capitalizeFirstLetter } from '../../utils/strings';


const ViewApplicantModal = (props) => {
    const { setModal } = useModal();
    const { jobPosting, jobApplication } = props;

    const header = (
        <div className='flex flex-row align-center justify-between w-100 pt-20 pb-20 background-secondary'
            style={{ borderBottom: `1px solid ${secondaryButton}` }}>
            <div style={{ marginLeft: "40px", fontWeight: "500" }}>{`${jobApplication.user.name}'s Application for ${props.jobPosting?.title}`}</div>
            <div className='flex flex-row align-center mr-20 cursor-pointer' onClick={() => setModal({ show: false })}>
                <div className='font-size-14 mr-5'>{"Close"}</div>
                <IoClose color={charcoal} size={20} />
            </div>
        </div>
    );

    const resumeLastUsed = jobApplication.profile?.assets?.resume?.lastUsed ? new Date(jobApplication.profile.assets.resume.lastUsed).toLocaleDateString() : "Never";
    const dayString = jobApplication.daysAvailable.map(capitalizeFirstLetter).join(", ") || "N/A";

    return (
        <div className='modal-content modal-medium'>
            {header}
            <div className='flex flex-column align-center justify-start w-80 mb-20'>
                <div className='w-100'>
                    <div className='mt-20 mb-5 font-size-large'>{"Contact Information"}</div>
                    <div className='mt-20 mb-10 flex flex-row align-center justify-between'>
                        <div>
                            <div>{"Name"}</div>
                            <div className='gray'>{jobApplication.user.name}</div>
                        </div>
                        <div>
                            <div>{"Email"}</div>
                            <div className='gray'>{jobApplication.user.email}</div>
                        </div>
                        <div>
                            <div>{"City Of Residence"}</div>
                            <div className='gray'>{jobApplication.user.location || "N/A"}</div>
                        </div>
                    </div>
                    <div className='mt-20 mb-10'>{"Resume"}</div>
                    <div className='w-30'>
                        <div className='resume-container cursor-pointer'>
                            <a href={jobApplication?.user?.assets?.resume?.url} download>
                                <div className='flex-column'>
                                    <div className='font-size-14'>
                                        {jobApplication.user?.assets?.resume ?
                                            <b className='aperol'>{"Resume"}</b> :
                                            <div className='danger flex flex-row align-center'>
                                                <IoWarning color={"rgb(181, 20, 20, 0.6)"} size={20} />
                                                <b className='ml-10'>{"No Resume Uploaded!"}</b>
                                            </div>
                                        }
                                    </div>
                                    {jobApplication.user?.assets?.resume &&
                                        <div className='font-size-12 gray'>{`Last Used: ${resumeLastUsed}`}</div>
                                    }
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='mt-20 mb-10'>{"Days of Availability To Work"}</div>
                    <div className='flex flex-row align-center mb-40'>
                        <div className='gray'>{dayString}</div>
                    </div>
                    <div className='divider' />
                    <div className='mt-20 mb-5 font-size-large'>{"Screening Questions"}</div>
                    {jobPosting.screeningQuestions.map((q, index) => (
                        <div className='mt-20 mb-10' key={q.questionText}>
                            <div className='charcoal mb-10'>{q.questionText}</div>
                            <div className='gray font-size-14'>{jobApplication.screeningAnswers[index]}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div >

    )
}

export default ViewApplicantModal