import { useState } from "react"
import {
    Form,
    FormInput,
} from './forgot-password.styles.jsx';
import { forgotPassword } from "../../firebase";
import { Button } from "../button.styles.jsx";
import { MdCheckCircle } from "react-icons/md";

function ForgotPasswordModal(props) {
    const { setModal, setAuthModal } = props;

    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const forgotPasswordHandler = (e) => {
        setErrors([]);
        setSuccess(false);
        setLoading(true);
        e.preventDefault();
        forgotPassword(email)
            .then(_ => setSuccess(true))
            .catch(errors => setErrors(errors))
            .finally(() => setLoading(false));
    }

    const renderSuccess = () => {
        return (
            <div className="flex flex-column align-center p-2">
                <MdCheckCircle size={50} color={"rgb(53,62,66)"} />
                <div className="mt-10 mb-10 charcoal font-size-18">{"Check your Email"}</div>
                <div className="mt-10 mb-10 font-size-15 charcoal text-align-center">
                    {`We've sent instructions on how to reset your password to `}<b>{email}</b>
                </div>
                <Button style={{ minWidth: "60%", marginTop: "15px" }} color={"grayInverted"} onClick={() => setModal({ show: false, modal: "forgot-password" })}>Done</Button>
            </div>
        )
    }

    const renderForm = () => {
        return (<Form onSubmit={forgotPasswordHandler} className="flex flex-column align-center">
            <h2 className="text-align-center mb-20">Forgot Password</h2>
            {errors && errors.length > 0 && errors.map((error) => <p style={{ color: "red", marginBottom: "10px" }}>{error}</p>)}
            <FormInput type='email' placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <Button disabled={loading || success} type='submit' color={"grayInverted"}>Submit</Button>
            <div
                className="gray text-align-center cursor-pointer font-size-14"
                onClick={() => {
                    setModal({
                        show: false,
                        modal: "forgot-password",
                    });
                    setAuthModal(true)
                }}>{"Back to Login"}</div>
        </Form>)
    }

    return <div className="forgot-password w-100"
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '400px'
        }}>
        {success ? renderSuccess() : renderForm()}
    </div>
}

export default ForgotPasswordModal