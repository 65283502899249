import React from 'react'
import Input from '../input/input.component'
import { Button } from '../button.styles'
import { useModal } from '../../contexts/modal.context'
import CreatePositionForm from '../create-position-form/create-position-form.component'

const PositionsApplicationsHeader = (props) => {
    const {
        jobPosting,
        search,
        setSearch
    } = props;
    const { setModal } = useModal();

    return (
        <div>
            <div className='flex flex-row align-center justify-between p-2 pb-0'>
                <h2 className='mr-20'>{jobPosting.title}</h2>
                <div className='w-40'>
                    <Input
                        type="text"
                        short
                        searchIcon
                        searchButton
                        placeholder='Search by Name'
                        value={search}
                        onChange={eve => setSearch(eve.target.value)}
                        fullWidth
                    />
                </div>
                <Button color="aperol" onClick={() => setModal({
                    show: true,
                    modal: "create-position",
                    children: <CreatePositionForm jobPosting={jobPosting} />,
                    closeIcon: false
                })}>
                    {"View Position Details"}
                </Button>
            </div>
            <div className='divider' />
        </div>

    )
}

export default PositionsApplicationsHeader