import React from 'react'
import betterShiftLogo from "../../images/betterShiftLogo-Vanilla.svg";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import {
    FaInstagram,
    FaLinkedin,
} from 'react-icons/fa'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    WebsiteRights,
    SocialIcons,
    SocialIconLink
} from './footer.styles'

import "../../styles/footer.scss";


const Footer = () => {
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({
        query: '(min-width: 768px)'
    })

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems >
                            <FooterLink to="/about"  >About</FooterLink>
                            <FooterLink to="/contact"  >Contact Us</FooterLink>
                            <FooterLink to="/site-guidelines" >Site Guidelines</FooterLink>
                        </FooterLinkItems>

                        <FooterLinkItems >
                            <FooterLink to="/terms-of-service" >Terms of Service</FooterLink>
                            <FooterLink to="/privacy-policy" >Privacy Policy</FooterLink>
                            <FooterLink to="/copyright-policy" >Copyright Policy</FooterLink>
                        </FooterLinkItems>
                        {!isDesktop && <SocialIcons>
                            <SocialIconLink href="https://www.instagram.com/bettershift/" target="_blank" aria-label="Instagram"><FaInstagram color='rgb(200,193,182)' /></SocialIconLink>
                            <SocialIconLink href="https://www.linkedin.com/company/better-shift/" target="_blank" aria-label="LinkedIn"><FaLinkedin color='rgb(200,193,182)' /></SocialIconLink>
                        </SocialIcons>}
                        {!isDesktop && <WebsiteRights>© {new Date().getFullYear()}, All Rights Reserved.</WebsiteRights>}
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <img src={betterShiftLogo} alt="bettershift logo" className="footer__logo" onClick={(e) => {
                            navigate('/')
                        }} />

                        {isDesktop && <WebsiteRights>Bettershift © {new Date().getFullYear()}, All Rights Reserved.</WebsiteRights>}
                        {isDesktop && <SocialIcons>
                            <SocialIconLink href="https://www.instagram.com/bettershift/" target="_blank" aria-label="Instagram"><FaInstagram color='rgb(200,193,182)' /></SocialIconLink>
                            <SocialIconLink href="https://www.linkedin.com/company/better-shift/" target="_blank" aria-label="LinkedIn"><FaLinkedin color='rgb(200,193,182)' /></SocialIconLink>
                        </SocialIcons>}
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
