import React from 'react'
import Input from '../input/input.component';
import { Button } from '../button.styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RESTAURANT_KEY } from '../../constants/queryCache';
import { useForm } from "react-hook-form"
import { createBusinessProfile } from '../../api/business';
import { setCurrentBusinessProfile } from '../../utils/storage';


const CreateBusinessProfile = (props) => {

    const queryClient = useQueryClient();
    const createProfileMutation = useMutation({
        mutationFn: createBusinessProfile,
        onSuccess: (data) => {
            queryClient.setQueryData([RESTAURANT_KEY, data.businessSlug], data)
            props.setModal({ modal: "create-biz-profile", show: false });
            setCurrentBusinessProfile(data.businessSlug);
            window.location = "/restaurant";
        },
    })

    const createProfile = (values) => {
        const { name, email, location, } = values;

        const newProfile = {
            name,
            email,
            location,
        }
        createProfileMutation.mutate(newProfile);
    }
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            location: "",
        }
    });

    return (
        <div className='user-profile__header__form'>
            <h1>{"Create Restaurant"}</h1>
            <form onSubmit={handleSubmit(createProfile)} style={{ padding: 0 }}>
                <h2 className='mt-20 mb-10'>{"Restaurant Name"}</h2>
                <Input fullWidth error={errors.name} inputProps={{
                    ...register("name", {
                        required: "Name is required", maxLength: { value: 80, message: "Max length of 80 characters" },
                    })
                }} />
                <h2 className='mt-20 mb-10'>{"Work Email Address"}</h2>
                <Input fullWidth type="email" inputProps={{
                    ...register("email", {
                        maxLength: { value: 200, message: "Max length of 200 characters" },
                    }), type: "email"
                }} />
                <h2 className='mt-20 mb-10'>{"Location"}</h2>
                <Input fullWidth inputProps={{
                    ...register("location", {
                        required: false,
                        maxLength: { value: 200, message: "Max length of 200 characters" },
                    })
                }} />

                <div className='flex flex-row align-center justify-end mt-20'>
                    <div className='cursor-pointer gray mr-20' onClick={() => props.setModal({ modal: "create-biz-profile", show: false })}>{"Cancel"}</div>
                    <Button color="aperol" type="submit">{"Create Restaurant"}</Button>
                </div>
            </form >
        </div>
    )
}

export default CreateBusinessProfile