export const getCurrentBusinessProfile = () => {
    const profile = sessionStorage.getItem('businessProfile');
    return profile ?? 'user';
}

export const setCurrentBusinessProfile = (profile) => {
    sessionStorage.setItem('businessProfile', profile);
}

export const clearCurrentBusinessProfile = () => {
    sessionStorage.removeItem('businessProfile');
}