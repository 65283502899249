import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { signup, signInGoogle } from '../../firebase';
import googleLogo from '../../images/google-logo.svg'
import "./signup.scss";

import {
  FormButton,
  FormH1,
  FormInput,
  Form,
} from './signup.styles'

import "../signin/signin.scss";
import { useNavigate } from 'react-router-dom';

const Background = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;

`;

const ModalWrapper = styled.div`
height: 600px;
overflow: hidden;
box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
background: white;
color: #000;
display: flex;
position: relative;
z-index: 10;
border-radius: 8px;
padding: 40px 30px;
padding-bottom: 0px;
@media screen and (max-width: 768px){
    width: 95%;
    margin: auto;
    }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 11;
  line-height: 1.8;
  color: #141414;

  p {
    margin-bottom: 1rem;
  }
  button {
    padding: 10px 24px;
    background: rgb(53,62,66);
    color: #fff;
    border: none;
  }
  margin-top: -40px;
  
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  padding: 0;
  z-index: 12;
`;

export const ModalSignUp = ({ showModalSignUp, setShowModalSignUp, setShowModal }) => {
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const navigate = useNavigate();

  function handleSignup(e) {
    e.preventDefault();
    setErrors([])

    setLoading(true);

    // sign up request
    signup({
      name,
      email,
      password,
    })
      .then(() => {
        setShowModalSignUp(false);
        navigate("/")
      })
      .catch((error) => setErrors([error.message]))
      .finally(() => setLoading(false));
  }

  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250
    },
  });

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModalSignUp(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalSignUp) {
        setShowModalSignUp(false);
      }
    },
    [setShowModalSignUp, showModalSignUp]
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );

  return (
    <>
      {showModalSignUp ? (
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <div data-aos="fade-zoom-in" data-aos-duration="500">
              <ModalWrapper showModalSignUp={showModalSignUp}>
                <ModalContent>
                  <Form onSubmit={handleSignup}>
                    <div className="form-header-grouping">
                      <FormH1>Welcome to BetterShift!</FormH1>
                      <div className="form-subtitle">Have a voice, tell your story.</div>
                    </div>
                    {<button type="button" className='google__sign-in-button' onClick={(e) => {
                      e.preventDefault();
                      signInGoogle().then(resp => {
                        setShowModalSignUp(false);
                        navigate("/");
                      })
                        .catch(error => setErrors([error.message]))
                        .finally(() => setLoading(false));

                    }}>
                      <img src={googleLogo} alt="google logo" />
                      Sign up with Google
                    </button>}
                    <div className="google__sign-in__divider">
                      <span className="google__sign-in__divider__text">Or</span>
                      <div className="google__sign-in__divider__line"></div>
                    </div>
                    {errors && errors.length > 0 && errors.map((error) => <p style={{ color: "red" }}>{error}</p>)}
                    {/* <label className="form-label">Name</label> */}
                    <FormInput placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} type='name' required ariaLabel="Name" />
                    {/* <label className="form-label">Email Address</label> */}
                    <FormInput placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} type='email' required ariaLabel="Email" />
                    {/* <label className="form-label">Password</label> */}
                    <FormInput placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} type='password' required ariaLabel="Password" />
                    {/* <label className="form-label">Confirm Password</label> */}
                    <FormInput placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type='password' required ariaLabel="Confirm password" />
                    <FormButton type='submit' disabled={loading}>Sign Up</FormButton>

                    <span className="signup-action">Have an account? &nbsp;<div style={{ cursor: "pointer", fontWeight: "500", color: "black" }}
                      onClick={() => {
                        setShowModalSignUp(false)
                        setShowModal(true)
                      }}>Sign In</div></span>
                  </Form>

                </ModalContent>
                <CloseModalButton
                  color='rgb(200,200,200)'
                  aria-label='Close modal'
                  onClick={() => setShowModalSignUp(prev => !prev)}
                />
              </ModalWrapper>
            </div>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};