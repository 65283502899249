import { useEffect, useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInAnonymously,
  updateProfile,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  confirmPasswordReset,
  updateEmail,
} from "firebase/auth";
import webClient from "./api/webClient";
import { isDev } from "./api/stage";
import { clearCurrentBusinessProfile } from "./utils/storage";

//import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyC8PGPYzqP6SoBwi16ialBmxd8rOKs3Qk0",
  authDomain: "bettershift-91adf.firebaseapp.com",
  projectId: "bettershift-91adf",
  storageBucket: "bettershift-91adf.appspot.com",
  messagingSenderId: "843115321315",
  appId: "1:843115321315:web:8e6c5fab6af11d197532d6",
  measurementId: "G-WP1FYMDZR4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);

export async function signup({ name, email, password }) {
  const result = await createUserWithEmailAndPassword(auth, email, password);
  await updateProfile(result.user, { displayName: name });
  return result.user;
}

export async function signInGoogle() {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  return result;
}

export const signInAnon = () => signInAnonymously(auth);

export function login({ email, password }) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function logout() {
  return signOut(auth);
}

export function forgotPassword(email) {
  return sendPasswordResetEmail(auth, email);
}

export function resetPassword({ oobCode, password }) {
  return confirmPasswordReset(auth, oobCode, password);
}

export function updateUser(user, data) {
  const profilePromise = updateProfile(user, data);
  if (!data.email) return profilePromise;
  const emailPromise = updateEmail(user, data.email);
  return Promise.all([profilePromise, emailPromise]);
}

// Custom Hook
export function useFirebase() {
  const [creating, setCreating] = useState(false);
  const [anonUser, setAnonUser] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    if (!currentUser && !anonUser && !creating && fetched) {
      setCreating(true);
      signInAnon()
        .finally(() => setCreating(false));
    }
  }, [creating, currentUser, anonUser, fetched])

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, user => {
      setFetched(true);
      if (!user) {
        clearCurrentBusinessProfile();
      }

      if (user?.isAnonymous) {
        setAnonUser(user)
      } else {
        setCurrentUser(user);
      }

      const tokenUser = user ?? anonUser;
      webClient.setTokenFunction(isDev ? () => tokenUser?.uid : () => tokenUser?.getIdToken());
    });
    return unsub;
  }, [fetched, anonUser])

  return { fetched, currentUser, anonUser };
}